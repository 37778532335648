body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-container {
    padding: 0 1em;
    color: #404040;

    .title-1 {
        text-align: center;
        margin: 1em 0;
    }

    .title-2 {
        text-align: center;
        margin: 0 0 1em;
    }

    p {
        text-align: center;
    }

    a {
        font-style: normal;
        text-decoration: none;
        font-weight: normal;
        color: #8884d8;

        &:hover {
            text-decoration: underline;
        }
    }

    table.table {
        width: 100%;
        margin-bottom: 1rem;
        color: #212529;
        border-collapse: collapse;
        font-size: 0.75em;

        th,
        td {
            text-align: left;
            padding: 0.75rem;
            vertical-align: top;
            border-top: 1px solid #dee2e6;
        }

        thead th {
            vertical-align: bottom;
            border-bottom: 2px solid #dee2e6;
        }

        tbody + tbody {
            border-top: 2px solid #dee2e6;
        }

        .table-sm th,
        .table-sm td {
            padding: 0.3rem;
        }

        .table-bordered {
            border: 1px solid #dee2e6;

            th,
            td {
                border: 1px solid #dee2e6;
            }

            thead th,
            thead td {
                border-bottom-width: 2px;
            }
        }

        .table-striped tbody tr:nth-of-type(odd) {
            background-color: rgba(0, 0, 0, 0.05);
        }

        .table-hover tbody tr:hover {
            color: #212529;
            background-color: rgba(0, 0, 0, 0.075);
        }
    }


    svg {
        path.country:hover {
            fill: #b0d0b0;
        }
        path.place:hover {
            stroke-width: 2px;
        }
        circle.place {
            fill: #008000;
        }
        circle.place:hover {
            fill: #008000;
        }
    }

    .controls-container {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .date-picker-container {
            margin-right: 20px;

            input {
                padding: 5px 10px;
                text-align: left;
                font-size: 1rem;
                width: 120px;
            }
        }

        .country-picker-container {
            margin-right: 20px;

            select {
                padding: 5px;
                font-size: 1rem;
                width: 200px;
            }
        }

        .day-ahead-price-picker-container {
            margin-right: 20px;

            select {
                padding: 5px;
                font-size: 1rem;
                width: 200px;
            }
        }
    }

    .chart-container {
        display: grid;
        gap: 16px;
        grid-template-columns: 1fr;

        .chart {
            margin: 0;
            border: 1px solid #a0a0a0;
            border-radius: 8px;
            padding: 2em;
            background-color: #f0f0f0;

            div.panel-switcher {
                margin-top: 10px;
            }

            div.svg {
                margin: 0;
                border: 1px solid #a0a0a0;
                border-radius: 8px;
                background-color: #d0d9ff;
                position: relative;

                div.svg-title {
                    position: absolute;
                    background-color: rgba(255, 255, 255, 0.5);
                    border-top-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                    padding: 8px 20px;
                    border-right: 1px solid #a0a0a0;
                    border-bottom: 1px solid #a0a0a0;
                    font-weight: bold;
                }

                div.copyright {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(255, 255, 255, 0.5);
                    border-top-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                    padding: 8px 20px;
                    border-left: 1px solid #a0a0a0;
                    border-top: 1px solid #a0a0a0;
                    font-style: italic;
                    font-size: 0.8em;
                }

                svg {
                    padding: 0;
                    margin: 0;
                    display: block;
                    border-radius: 8px;
                }
            }

            .custom-tooltip {
                background-color: white;
                border: 1px solid #a0a0a0;
                border-radius: 8px;
                padding: 0 1em;
                font-size: .75em;

                .label {
                    font-weight: bold;
                }

                ul {
                    padding-left: 0;
                    list-style: none;

                    li {
                        color: #404040;
                    }
                }
            }
        }
    }

    .info-container {
        margin-bottom: 5em;
    }
}

@media (min-width: 1200px) {
    .app-container {
        .chart-container {
            grid-template-columns: 1fr 1fr;
        }
    }
}

@media (min-width: 1920px) {
    .app-container {
        .chart-container {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}
